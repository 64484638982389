@use './main' as *;

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: $mainFont, sans-serif;
  margin: 0;
}

.clickable {
  cursor: pointer;
  text-decoration: none;
}

.clickable:hover {
  opacity: 0.8;
}

.no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

.content-padding {
  padding: 26px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-100vh {
  width: 100vh;
}

.h-100vh {
  height: 100vh;
}

.border-0 {
  border: 0;
}

.flex {
  display: flex;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-space-between {
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

.center-align {
  align-items: center;
  display: flex;
  justify-content: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.card {
  background: rgb(255 255 255);
  border: 1px solid rgb(221 221 221);
  border-radius: 12px;
  overflow: hidden;
  padding: 32px;

  @include smallAndBelow {
    padding: 16px;
  }
}

.button-shadow {
  box-shadow: 10px 24px 40px -10px rgba(0 0 0 / 50%);
}

.component-shadow {
  box-shadow: 10px 24px 40px -10px rgba(0 0 0 / 50%);
}

.component-shadow-small {
  box-shadow: 0 24px 40px -10px rgba(0 0 0 / 25%);
}

#app-background {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.z-100 {
  z-index: 100;
}

.self-end {
  align-self: flex-end;
}